export default {
  data () {
    return {
      taxAgencyLoader: false
    }
  },
  computed: {
    baseHandledErrors: () => ([
      {
        action: 'Cargar certificado digital',
        message: 'La organización no tiene un certificado digital válido.',
        route: { name: 'IrsBuiltIn', query: { ref: 'certificate' } },
        snippet: 'un certificado digital válido'
      },
      {
        action: 'Actualizar información',
        message: 'La información básica de la organización es requerida (estado y dirección).',
        route: { name: 'AccountBasic' },
        snippet: 'state and address is required'
      },
      {
        action: 'Actualizar información fiscal',
        message: 'La información fiscal de la organización es requerida.',
        route: { name: 'IrsBuiltIn', query: { ref: 'taxsettings' } },
        snippet: 'activity and activity description is required'
      }
    ])
  },
  methods: {
    taxAgencyService (store, resource, service) {
      this.taxAgencyLoader = true
      this.$store.dispatch(`${store}/RETRIEVE`, {
        resource: resource,
        id: this.$route.params.id,
        detail: service,
        loader: false
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.taxAgencyLoader = false
      })
    }
  }
}