<template>
  <div class="d-flex mt-2">
    <div class="align-stretch">
      <v-skeleton-loader class="bgPanel rounded-md" type="image, article" width="400" min-height="calc(100% - 155px)" />
    </div>
    <div class="flex-grow-1 ml-10">
      <div class="d-block" v-for="item in 3" :key="item">
        <v-skeleton-loader class="mb-2 panel px-1 rounded-md" transition="fade-transition" type="image" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  })
}
</script>
<style>
.panel .v-skeleton-loader__image {
  height: 65px;
  border-radius: 10px;
}
</style>