<template>
  <div align="center" justify="center">
    <header-admiral :instance="instance" :activeDocument="activeDocument" :action="action" :message="message" :color-background="colorBackground" @update:message="updateMessage($event)" />
    <email-footer />
  </div>
</template>
<script>
import EmailFooter from '@/components/reminders/EmailFooter'
import HeaderAdmiral from '@/components/reminders/documents/headers/Admiral'
import ReminderViewMixin from '@/mixins/ReminderViewMixin'
export default {
  props: {
    template: {
      type: String,
      required: false,
      default: () => ('admiral')
    }
  },
  components: {
    EmailFooter,
    HeaderAdmiral
  },
  mixins: [
    ReminderViewMixin
  ]
}
</script>