import Vue from 'vue'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VueTextareaAutosize from 'vue-textarea-autosize'

Vue.use(VueTextareaAutosize)

export default {
  props: {
    instance: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    activeDocument: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    action: {
      type: String,
      required: false,
      default: () => (null)
    },
    message: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    colorBackground: {
      type: String,
      required: false,
      default () {
        return '#4051B5'
      }
    },
    colorAsset: {
      type: String,
      required: false,
      default () {
        return '#525F7F'
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Escribe un mensaje...'
    }
  },
  mixins: [
    GenericViewMixin
  ],
  computed: {
    messageBody: {
      set () {
        return this.message
      },
      get () {
        return this.message
      }
    }
  },
  methods: {
    updateMessage (e) {
      this.$emit('update:message', e)
    }
  }
}